@font-face {
font-family: '__FrameGothic_9d88cd';
src: url(/_next/static/media/047e9a130b09a20c-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400 600;
font-style: normal;
}@font-face {font-family: '__FrameGothic_Fallback_9d88cd';src: local("Arial");ascent-override: 90.64%;descent-override: 22.95%;line-gap-override: 0.00%;size-adjust: 105.03%
}.__className_9d88cd {font-family: '__FrameGothic_9d88cd', '__FrameGothic_Fallback_9d88cd';font-style: normal
}.__variable_9d88cd {--font-main: '__FrameGothic_9d88cd', '__FrameGothic_Fallback_9d88cd'
}

@font-face {
font-family: '__NeueMachinaInktrap_5c99cd';
src: url(/_next/static/media/19874d4df76220a9-s.p.woff) format('woff');
font-display: block;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__NeueMachinaInktrap_Fallback_5c99cd';src: local("Arial");ascent-override: 62.58%;descent-override: 19.58%;line-gap-override: 5.11%;size-adjust: 117.45%
}.__className_5c99cd {font-family: '__NeueMachinaInktrap_5c99cd', '__NeueMachinaInktrap_Fallback_5c99cd';font-weight: 400;font-style: normal
}.__variable_5c99cd {--font-secondary: '__NeueMachinaInktrap_5c99cd', '__NeueMachinaInktrap_Fallback_5c99cd'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e5ab12';
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e5ab12';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_e5ab12 {font-family: '__Inter_e5ab12', '__Inter_Fallback_e5ab12';font-style: normal
}.__variable_e5ab12 {--font-tertiary: '__Inter_e5ab12', '__Inter_Fallback_e5ab12'
}

:root {
  --overlay-heavy: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(180deg, rgb(0 0 0 / 0%) 50%, #000000 100%), linear-gradient(7.96deg, #000000 14.71%, rgb(0 0 0 / 0%) 78.17%);
  --overlay-medium: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 67.2%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
  --overlay-light: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 50.4%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
  --overlay-dim80: rgb(0 0 0 / 80%);
  --overlay-dim60: rgb(0 0 0 / 60%);
  --overlay-dim40: rgb(0 0 0 / 40%);
  --overlay-dim: rgb(0 0 0 / 20%);
  --overlay-none: none;
  --color-alpha-grey-80: rgba(46, 47, 64, 0.8);
  --color-alpha-grey-30: rgba(54, 56, 74, 0.2);
  --color-alpha-perano-10: rgba(189, 189, 244, 0.1);
  --color-alpha-cinder-50: rgba(13, 13, 24, 0.5);
  --color-alpha-hawkes-60: rgba(209, 209, 251, 0.6);
  --color-alpha-hawkes-20: rgba(209, 209, 251, 0.2);
  --color-alpha-black-5: rgba(0, 0, 0, 0.05);
  --color-alpha-black-10: rgba(0, 0, 0, 0.1);
  --color-alpha-black-20: rgba(0, 0, 0, 0.2);
  --color-alpha-black-25: rgba(0, 0, 0, 0.25);
  --color-alpha-black-30: rgba(0, 0, 0, 0.3);
  --color-alpha-black-40: rgba(0, 0, 0, 0.4);
  --color-alpha-black-50: rgba(0, 0, 0, 0.5);
  --color-alpha-black-60: rgba(0, 0, 0, 0.6);
  --color-alpha-black-70: rgba(0, 0, 0, 0.7);
  --color-alpha-black-80: rgba(0, 0, 0, 0.8);
  --color-alpha-black-90: rgba(0, 0, 0, 0.9);
  --color-alpha-black-100: rgba(0, 0, 0, 1);
  --color-alpha-dark-5: rgba(13, 13, 24, 0.05);
  --color-alpha-dark-10: rgba(13, 13, 24, 0.1);
  --color-alpha-dark-20: rgba(13, 13, 24, 0.2);
  --color-alpha-dark-30: rgba(13, 13, 24, 0.3);
  --color-alpha-dark-40: rgba(13, 13, 24, 0.4);
  --color-alpha-dark-50: rgba(13, 13, 24, 0.5);
  --color-alpha-dark-60: rgba(13, 13, 24, 0.6);
  --color-alpha-dark-70: rgba(13, 13, 24, 0.7);
  --color-alpha-dark-80: rgba(13, 13, 24, 0.8);
  --color-alpha-dark-90: rgba(13, 13, 24, 0.9);
  --color-alpha-light-5: rgba(157, 157, 255, 0.05);
  --color-alpha-light-10: rgba(189, 189, 244, 0.1);
  --color-alpha-light-15: rgba(209, 209, 251, 0.15);
  --color-alpha-light-20: rgba(209, 209, 251, 0.2);
  --color-alpha-light-30: rgba(220, 220, 255, 0.3);
  --color-alpha-light-40: rgba(228, 228, 255, 0.4);
  --color-alpha-light-50: rgba(234, 234, 255, 0.5);
  --color-alpha-light-60: rgba(234, 234, 255, 0.6);
  --color-alpha-light-70: rgba(233, 233, 255, 0.7);
  --color-alpha-light-80: rgba(240, 240, 255, 0.8);
  --color-alpha-light-90: rgba(241, 244, 255, 0.9);
  --color-grey-100: rgba(252, 252, 252, 1);
  --color-grey-97: rgba(245, 245, 248, 1);
  --color-grey-95: rgba(240, 240, 247, 1);
  --color-grey-93: rgba(232, 233, 243, 1);
  --color-grey-90: rgba(222, 223, 238, 1);
  --color-grey-88: rgba(213, 214, 234, 1);
  --color-grey-80: rgba(193, 194, 217, 1);
  --color-grey-70: rgba(163, 164, 191, 1);
  --color-grey-60: rgba(129, 133, 159, 1);
  --color-grey-50: rgba(106, 107, 131, 1);
  --color-grey-40: rgba(79, 81, 103, 1);
  --color-grey-30: rgba(54, 56, 74, 1);
  --color-grey-20: rgba(46, 47, 64, 1);
  --color-grey-15: rgba(37, 39, 54, 1);
  --color-grey-12: rgba(29, 31, 44, 1);
  --color-grey-10: rgba(22, 23, 34, 1);
  --color-grey-08: rgba(18, 19, 29, 1);
  --color-grey-05: rgba(13, 13, 24, 1);
  --color-grey-00: rgba(0, 0, 0, 1);
  --color-coral-dark: rgba(227, 107, 82, 1);
  --color-coral-light: rgba(252, 164, 147, 1);
  --color-coral: rgba(252, 133, 109, 1);
  --color-iris-dark: rgba(99, 44, 218, 1);
  --color-iris-light: rgba(159, 117, 249, 1);
  --color-iris: rgba(137, 82, 253, 1);
  --color-electric-dark: rgba(0, 184, 185, 1);
  --color-electric-light: rgba(135, 255, 255, 1);
  --color-electric: rgba(71, 235, 235, 1);
  --color-cobalt-dark: rgba(82, 55, 249, 1);
  --color-cobalt-light: rgba(99, 105, 255, 1);
  --color-cobalt-20: rgba(91, 83, 255, 0.2);
  --color-cobalt: rgba(91, 83, 255, 1);
  --color-turquoise-triumph: rgba(97, 153, 246, 1);
  --spacer-4: 0.4rem;
  --spacer-6: 0.6rem;
  --spacer-8: 0.8rem;
  --spacer-10: 1rem;
  --spacer-12: 1.2rem;
  --spacer-16: 1.6rem;
  --spacer-18: 1.8rem;
  --spacer-20: 2rem;
  --spacer-24: 2.4rem;
  --spacer-28: 2.8rem;
  --spacer-32: 3.2rem;
  --spacer-40: 4rem;
  --spacer-48: 4.8rem;
  --spacer-54: 5.4rem;
  --spacer-64: 6.4rem;
  --spacer-72: 7.2rem;
  --spacer-84: 8.4rem;
  --spacer-96: 9.6rem;
  --spacer-124: 12.4rem;
  --spacer-160: 16rem;
  --spacer-192: 19.2rem;
  --spacer-240: 24rem;
  --spacer-320: 32rem;
  --spacer-480: 48rem;
  --spacer-0: 0rem;
}

#onetrust-consent-sdk,
#ot-cookie-settings {
  font-family: var(--font-main, sans-serif);
}

#onetrust-button-group:has(button:nth-last-child(2):first-child) button:last-child {
  /* styles for the last button when there are exactly 2 buttons */
  grid-column: span 2;
}

